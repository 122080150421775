
import { Vue, Component } from "vue-property-decorator";
import Company from "@/types/company";
import Page from "@/components/core/dashboard/Page.vue";
import CompaniesTable from "@/components/companies/CompaniesTable.vue";
import CompanyManageModal from "@/components/companies/CompanyManageModal.vue";
import CompanyCreateModal from "@/components/companies/CompanyCreateModal.vue";
import GroupsManage from "@/components/companies/GroupsManage.vue";
import ManageEvent from "@/types/manage-event";
import CompanyService from "@/services/company-service";
import { ListCompaniesParams } from "../services/company-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import CompanyStatus from "@/types/company-status";
import CompanyUpdateStatusModal from "@/components/companies/CompanyUpdateStatusModal.vue";
import CompanyObservation from "../types/company-observation";

type CompanyItem = Omit<Company, "contract" | "segmentName" | "segmentId"> & {
  segmentName: string | null;
  segmentId: number | null;
};

@Component({
  components: {
    Page,
    CompanyManageModal,
    CompanyCreateModal,
    CompaniesTable,
    GroupsManage,
    CompanyUpdateStatusModal
  }
})
export default class Companies extends Vue {
  // Modals

  showManageCompanyModal: boolean;
  showCreateCompanyModal: boolean;

  // Company-related objects
  defaultItemCompany: CompanyItem = {
    id: 0,
    groupId: 0,
    statusId: 0,
    name: "",
    cnpj: "",
    segmentName: null,
    segmentId: null,
    zipCode: "",
    address: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
    phone: "",
    status: {
      id: 1,
      name: "SOLICITADO",
      description: "Solicitado"
    } as CompanyStatus,
    observations: [],
    partnership: {
      payCutDay: "",
      expirationDay: "",
      paymentDay: "",
      gracePeriodForExpirationDay: ""
    },
    index: null,
    consignetPartnershipId: null,
    useConsignetPartnership: false,
    useAutomaticEndorsement: false,
    useCustomProducts: false,
    products: []
  };
  itemCompany: CompanyItem;
  companiesData: { items: Array<Company>; total: number };
  refreshCompanies: number = 0;
  showCompanyUpdateStatusModal: boolean;
  observations: CompanyObservation[] = [];

  service: CompanyService;

  constructor() {
    super();

    this.showManageCompanyModal = false;
    this.showCreateCompanyModal = false;
    this.showCompanyUpdateStatusModal = false;

    this.itemCompany = { ...this.defaultItemCompany };
    this.companiesData = {
      items: [],
      total: 0
    };

    this.service = CompanyService.getInstance();
  }

  async created(): Promise<void> {
    // Fetch data once page is created
    this.fetchCompanies({ page: 1, limit: 10 });
    this.loadObservations();
  }

  handleAction($event: ManageEvent): void {
    const item = { ...$event?.item };

    if ("manage" in $event) {
      if ($event.type === "Company") {
        this.itemCompany = item;
        this.showManageCompanyModal = $event.manage!;
      }
    }
  }

  handleClose(): void {
    // Close all modals
    this.showManageCompanyModal = false;

    // Reset items
    this.itemCompany = { ...this.defaultItemCompany };
  }

  async handleSaveCompany(): Promise<void> {
    const {
      id,
      groupId,
      name: companyName,
      cnpj,
      segmentName,
      segmentId,
      creditPolicyId,
      zipCode,
      address,
      number,
      complement,
      district,
      city,
      state,
      phone,
      partnership,
      consignetPartnershipId,
      useConsignetPartnership,
      useAutomaticEndorsement,
      observations,
      batchAnticipationDate
    } = this.itemCompany;

    let error, company;

    // Update existing Company
    [error, company] = await this.service.updateCompany(id, {
      groupId,
      name: companyName,
      cnpj: cnpj.replace(/[^\d]+/g, ""),
      segmentName: segmentName!,
      segmentId: segmentId!,
      creditPolicyId: creditPolicyId ? creditPolicyId : undefined,
      zipCode,
      address,
      number,
      complement,
      district,
      city,
      state,
      phone,
      useConsignetPartnership,
      consignetPartnershipId,
      useAutomaticEndorsement,
      observations,
      batchAnticipationDate: batchAnticipationDate
        ? batchAnticipationDate
        : undefined,
      ...(this.hasPermissions(["EDITAR_DATAS_CONVENIO_EMPRESA"])
        ? {
            payCutDay: partnership.payCutDay,
            expirationDay: partnership.expirationDay,
            paymentDay: partnership.paymentDay,
            gracePeriodForExpirationDay:
              partnership.gracePeriodForExpirationDay || "0"
          }
        : {
            payCutDay: undefined,
            expirationDay: undefined,
            paymentDay: undefined,
            gracePeriodForExpirationDay: undefined
          })
    });

    if (!error && company) {
      this.handleClose();
      this.$notify({ type: "success", text: "Empresa salva com sucesso." });
      this.handleRefreshCompanies();
    } else {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }
  }

  async fetchCompanies(options: ListCompaniesParams): Promise<void> {
    const {
      page,
      limit,
      sort,
      statusId,
      initialDate,
      endDate,
      groupId,
      partner
    } = options;
    var search = options.search;
    if (
      search &&
      /[0-9]/.test(search.replace(".", "").replace("/", "").replace("-", ""))
    ) {
      search = search.replace(/[^\d]+/g, "");
    }
    const [error, companiesData] = await this.service.listCompanies({
      page,
      limit,
      search,
      sort,
      statusId,
      groupId,
      partner,
      initialDate,
      endDate
    });

    if (!error) {
      this.companiesData = companiesData!;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  async loadObservations(): Promise<void> {
    const [error, observations] = await this.service.listObservations();
    if (!error) {
      this.observations = observations!;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  handleCompanyCreated(): void {
    this.fetchCompanies({ page: 1, limit: 10 });
    this.showCreateCompanyModal = false;
  }

  async handleActivateCompany() {
    const [error, updatedCompany] = await this.service.activateCompany(
      this.itemCompany.id
    );
    if (error) {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else if (updatedCompany) {
      this.companiesData.items = this.companiesData.items.map((item) => {
        if (item.id === this.itemCompany.id) {
          item.statusId = updatedCompany.statusId;
          item.status = updatedCompany.status;
        }
        return item;
      });
      this.handleClose();
      this.$notify({
        type: "success",
        text: "A empresa foi ativada!"
      });
    }
  }
  async handleBlockCompany() {
    const [error, updatedCompany] = await this.service.blockCompany(
      this.itemCompany.id
    );
    if (error) {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else if (updatedCompany) {
      this.companiesData.items = this.companiesData.items.map((item) => {
        if (item.id === this.itemCompany.id) {
          item.statusId = updatedCompany.statusId;
          item.status = updatedCompany.status;
        }
        return item;
      });
      this.handleClose();
      this.$notify({
        type: "success",
        text: "A empresa foi bloqueada!"
      });
    }
  }

  async handleDeleteCompany(): Promise<void> {
    const userResponse = confirm(
      "Você está prestes a excluir uma empresa, com isso a base de margem da mesma será deletada tambem. Deseja continuar?"
    );

    if (userResponse && this.itemCompany.id) {
      const [error, companyDeleted] = await this.service.deleteCompany(
        this.itemCompany.id
      );
      if (error || !companyDeleted) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      }
      this.handleClose();
      this.$notify({ type: "success", text: "Empresa deletada com sucesso" });
    }
  }

  async updateCompanyStatus(item: {
    id: number;
    status: string;
    observation: string;
  }): Promise<void> {
    const [err] = await this.service.updateCompanyStatus(item.id, {
      status: item.status,
      notes: item.observation
    });

    this.closeCompanyUpdateStatusModal();
    if (!err) {
      this.handleRefreshCompanies();
      this.$notify({
        title: "Empresa",
        type: "success",
        text: "Status alterado com sucesso."
      });
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Empresa", type: "error", text: message });
    }
  }

  closeCompanyUpdateStatusModal(): void {
    this.itemCompany = this.defaultItemCompany;
    this.showCompanyUpdateStatusModal = false;
  }

  updateStatusModal(item: Company) {
    this.showCompanyUpdateStatusModal = true;
    this.itemCompany = { ...item };
  }

  handleRefreshCompanies() {
    this.refreshCompanies++;
  }
}
