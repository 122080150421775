
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Company from "@/types/company";
import CompanyStatus, { statusList } from "@/types/company-status";
import { DataOptions, DataTableHeader } from "vuetify";
import debounce from "debounce";
import { ListCompaniesParams } from "../../services/company-service";
import dayjs from "@/plugins/day-js";
import { format as formatCNPJ } from "@/utils/cnpj";
import DatePicker from "@/components/DatePicker.vue";
import _ from "lodash";
import ShowCompanyStatus from "@/components/companies/CompanyStatus.vue";
import PartnerService, { PartnerList } from "@/services/partner-service";
import CompanyService from "@/services/company-service";
import MarginBaseService, {
  MarginBaseRegisterCountList
} from "@/services/margin-base-service";
import CompanyObservation from "../../types/company-observation";
import { GroupList } from "@/types/group";
import { exportToSpreadsheet } from "@/utils/exportToSpreadsheet";

@Component({
  components: {
    ShowCompanyStatus,
    DatePicker
  }
})
export default class CompaniesTable extends Vue {
  @Prop({ default: [] }) readonly companiesData!: {
    items: Array<Company>;
    total: number;
  };
  @Prop() readonly observations!: CompanyObservation[];
  @Prop() fetch!: (options: ListCompaniesParams) => Promise<void>;
  @Prop() refresh!: number;

  formatCNPJ = formatCNPJ;
  options: DataOptions;
  headers: Array<DataTableHeader>;
  search: string;
  status: number | null = null;
  groupId?: number;
  partner?: number;
  initialDate: string = "";
  endDate: string = "";
  searchFunction: any;
  loading: boolean;
  statusList: CompanyStatus[] = statusList;

  loadingGroups = false;
  groups: GroupList = { items: [] };
  groupSearch: string | null = null;

  loadingPartners = false;
  partners: PartnerList = { data: [], total: 0 };
  partnerSearch: string | null = null;

  companyService: CompanyService;
  partnerService: PartnerService;
  marginBaseService: MarginBaseService;

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.partnerService = PartnerService.getInstance();
    this.marginBaseService = MarginBaseService.getInstance();
    this.headers = [
      { text: "Cadastro", value: "createdAt", sortable: true, width: "5%" },
      { text: "Empresa", value: "name", sortable: true, width: "15%" },
      { text: "CNPJ", value: "cnpj", sortable: false, width: "12%" },
      { text: "Cidade", value: "city", sortable: false, width: "10%" },
      { text: "UF", value: "state", sortable: false, width: "3%" },
      { text: "Telefone", value: "phone", sortable: false, width: "12%" },
      { text: "Grupo", value: "group.name", sortable: false, width: "15%" },
      {
        text: "Parceiro  ",
        value: "group.partnerName",
        sortable: false,
        width: "10%"
      },
      { text: "Status", value: "status", sortable: false, width: "8%" },
      {
        text: "Observação",
        value: "observations",
        sortable: false,
        width: "10%"
      },
      { text: "Ações", value: "actions", sortable: false, width: "140px" }
    ];

    this.search = "";

    this.searchFunction = debounce(
      async (
        page: number,
        itemsPerPage: number,
        search: string,
        statusId?: number
      ) => {
        const data = {
          page: page,
          limit: itemsPerPage,
          search: search,
          statusId,
          groupId: this.groupId,
          partner: this.partner
        };

        await this.fetch(data);
        this.stopLoading();
      },
      700
    );
    this.options = {} as DataOptions;
    this.loading = false;
  }

  @Watch("options")
  onOptionsChanged(val: DataOptions) {
    const { page, itemsPerPage, sortBy, sortDesc } = val;

    const attr = sortBy[0];
    const order = sortDesc[0] ? "DESC" : "ASC";

    this.fetch({
      page: page,
      limit: itemsPerPage,
      search: this.search,
      statusId: this.status ? this.status : undefined,
      groupId: this.groupId,
      partner: this.partner ? this.partner : undefined,
      initialDate: this.initialDate,
      endDate: this.endDate,
      sort: attr ? `${attr}:${order}` : undefined
    });
  }

  @Watch("search")
  onSearchChanged(val: string) {
    this.startLoading();
    const { itemsPerPage } = this.options;
    // When search changes, we should go back to the first page
    this.searchFunction(1, itemsPerPage, val, this.status);
  }

  @Watch("status")
  async onFiltersChange() {
    this.startLoading();
    this.options.page = 1;
    const { itemsPerPage, page, sortBy, sortDesc } = this.options;

    const attr = sortBy[0];
    const order = sortDesc[0] ? "DESC" : "ASC";

    await this.fetch({
      page: page,
      limit: itemsPerPage,
      search: this.search,
      statusId: this.status ? this.status : undefined,
      groupId: this.groupId,
      partner: this.partner,
      initialDate: this.initialDate,
      endDate: this.endDate,
      sort: attr ? `${attr}:${order}` : undefined
    });
    this.stopLoading();
  }

  @Watch("createdAt")
  async onCreatedAtChanged(): Promise<void> {
    this.startLoading();
    const { itemsPerPage, page, sortBy, sortDesc } = this.options;

    const attr = sortBy[0];
    const order = sortDesc[0] ? "DESC" : "ASC";

    await this.fetch({
      page: page,
      limit: itemsPerPage,
      search: this.search,
      statusId: this.status ? this.status : undefined,
      groupId: this.groupId,
      partner: this.partner,
      initialDate: this.initialDate,
      endDate: this.endDate,
      sort: attr ? `${attr}:${order}` : undefined
    });
    this.stopLoading();
  }

  @Watch("refresh")
  onRefreshChange() {
    this.options = { ...this.options };
  }

  translateObservation(observationName: string): string {
    return (
      this.observations.find((o) => o.name === observationName)?.description ||
      "Desconhecido"
    );
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  formatDateCompact(date: string) {
    return dayjs(date).format("DD/MM/YY");
  }

  emitAction(item: Company, operation: { manage?: boolean; delete?: boolean }) {
    item.index =
      this.companiesData.items.indexOf(item) > -1
        ? this.companiesData.items.indexOf(item)
        : null;
    this.$emit("action", {
      item,
      ...operation
    });
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

  async downloadExcel(): Promise<void> {
    const sheet = [
      [
        "Data Cadastro",
        "Empresa",
        "CNPJ",
        "Segmento",
        "Status",
        "Telefone",
        "Dia corte",
        "Dia Vencimento",
        "Parceiro",
        "Estado",
        "Cidade",
        "Margem"
      ]
    ];
    const companiesIds = this.companiesData.items.map((c) => c.id);

    let marginCountData: MarginBaseRegisterCountList = [];

    for (const companiesIdsChunk of _.chunk(companiesIds, 50)) {
      const data = await this.loadMarginCountForCompanies(companiesIdsChunk);
      if (data) marginCountData = marginCountData.concat(data);
    }

    this.companiesData.items.forEach((item) => {
      sheet.push([
        item.createdAt ? this.formatDate(item.createdAt) : "",
        item.name,
        this.formatCNPJ(item.cnpj),
        item.segmentName,
        item.status.name,
        item.phone,
        item.partnership.payCutDay,
        item.partnership.expirationDay,
        item.group?.partnerName || "",
        item.state,
        item.city,
        marginCountData!
          .find((marginData) => marginData.companyId === item.id)!
          .marginBaseRegisterCount.toString()
      ]);
    });

    exportToSpreadsheet({
      data: sheet,
      fileName: "gooroo-empresas-" + dayjs().format("YYYY-MM-DD-HH-mm-ss")
    });
  }

  searchGroups = debounce(async () => {
    await this.fetchGroups();
  }, 700);

  async fetchGroups(): Promise<GroupList> {
    if (this.hasPermissions(["LISTAR_GRUPOS"])) {
      this.loadingGroups = true;
      const [error, groups] = await this.companyService.listGroups({
        page: 1,
        limit: 15,
        groupName: this.groupSearch || "",
        sort: "name:ASC",
        companyNameOrCnpj: "",
        adminEmail: "",
        adminPhone: ""
      });

      this.loadingGroups = false;

      if (error || !groups) {
        this.$notify({
          type: "error",
          text: "Não foi possível obter a lista de empresas"
        });
      } else {
        this.groups = groups;
      }
    }
    return this.groups;
  }

  searchPartners = debounce(async () => {
    await this.fetchPartners();
  }, 700);

  async fetchPartners(): Promise<PartnerList> {
    if (this.hasPermissions(["LISTAR_PARCEIROS"])) {
      this.loadingPartners = true;
      const [error, partners] = await this.partnerService.listPartners({
        page: 1,
        limit: 15,
        name: this.partnerSearch || ""
      });

      this.loadingPartners = false;

      if (error || !partners) {
        this.$notify({
          type: "error",
          text: "Não foi possível obter a lista de parceiros"
        });
      } else {
        this.partners = partners;
      }
    }
    return this.partners;
  }

  async loadMarginCountForCompanies(companiesIds: number[]) {
    const [error, marginCountData] =
      await this.marginBaseService.listMarginBaseRegisterCountForCompanies({
        companiesIds
      });

    if (error) {
      this.$notify({
        type: "error",
        text: "Não foi possível obter os dados de margem"
      });
    }

    return marginCountData;
  }

  updateCompanyStatus(item: Company): void {
    this.$emit("updateStatus", item);
  }
}
