export const calculateDefaultPaymentCutDay = (
  expirationDay: number
): number => {
  if (isNaN(expirationDay) || expirationDay <= 0 || expirationDay > 27) {
    throw new Error(`Invalid expiration day ${expirationDay}`);
  }

  const increaseDaysBetweenExpirationAndCutDay = 10;
  const maxDayAllowedInMonth = 27;

  let paymentCutDay;

  const candidatePaymentCutDay =
    expirationDay + increaseDaysBetweenExpirationAndCutDay;

  if (candidatePaymentCutDay > maxDayAllowedInMonth) {
    paymentCutDay = Math.abs(candidatePaymentCutDay - maxDayAllowedInMonth);
  } else {
    paymentCutDay = candidatePaymentCutDay;
  }

  return paymentCutDay;
};
