
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Group from "@/types/group";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import CompanyService, {
  ListCompaniesResponse
} from "@/services/company-service";
import store from "@/store";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import User from "@/types/user";
import dayjs from "@/plugins/day-js";
import { format as formatCNPJ } from "@/utils/cnpj";
import CompanyStatus from "@/components/companies/CompanyStatus.vue";
import UsersManageModal from "@/components/users/UsersManageModal.vue";
import SafetyService from "@/services/safety-service";
import CompanyDocument from "@/types/company-document";
import CompanyDocumentType from "@/types/company-document-type";
import EletronicSignatureService from "@/services/eletronic-signature-service";
import Partner from "../../types/partner";
import PartnerService from "../../services/partner-service";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";
import { INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE } from "@/utils/isValidPassword";
import ApiClients from "@/types/api-client";

interface GroupForm {
  name: string;
  statusId: number;
  admin_name: string;
  admin_phone: string;
  admin_email: string;
  admin_password: string;
  requireBorrowerSendDocuments?: boolean;
  allowApi?: boolean;
  apiClientId?: number;
  partnerId?: number;
}

export enum GroupStatusEnum {
  ACTIVE = 1,
  INACTIVE = 2
}

@Component({
  components: { ValidationObserver, Modal, CompanyStatus, UsersManageModal },
  directives: { mask }
})
export default class GroupsManageModal extends Vue {
  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;
  INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE =
    INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE;
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
  formatCNPJ = formatCNPJ;
  service: CompanyService;
  partnerService: PartnerService;
  safetyService: SafetyService;
  eletronicSignatureService: EletronicSignatureService;
  @Prop() readonly group: Group | undefined;
  companyDocuments: CompanyDocument[] = [];
  partnersList: Array<Partner> = [];
  partnersPage: number = 1;
  partnersLimit: number = -1;
  isLoadingPartnersList: boolean = false;
  loading: boolean = false;
  form: GroupForm = {
    statusId: 0,
    name: "",
    admin_name: "",
    admin_phone: "",
    admin_email: "",
    admin_password: "",
    requireBorrowerSendDocuments: undefined,
    apiClientId: undefined,
    allowApi: undefined,
    partnerId: undefined
  };
  clientsApiList: ApiClients[] = [];

  groupStatusActive = GroupStatusEnum.ACTIVE;
  groupStatusInactive = GroupStatusEnum.INACTIVE;

  editUser: User | null = null;
  users: {
    items: User[];
    total: number;
  } = {
    items: [],
    total: 0
  };
  loadingUsers = false;
  usersHeaders = [
    { text: "Nome", value: "name", sortable: false },
    { text: "Email", value: "email", sortable: false },
    { text: "Telefone", value: "phone", sortable: false },
    {
      text: "Ações",
      value: "actions",
      sortable: false,
      cellClass: "text-end",
      class: "text-end"
    }
  ];

  companies: ListCompaniesResponse = {
    items: [],
    total: 0
  };

  loadingCompanies = false;
  companiesHeaders = [
    { text: "Nome da empresa", sortable: true, value: "name" },
    { text: "CNPJ", sortable: true, value: "cnpj" },
    { text: "Status", sortable: false, value: "status" }
  ];

  constructor() {
    super();
    this.service = CompanyService.getInstance();
    this.partnerService = PartnerService.getInstance();
    this.safetyService = SafetyService.getInstance();
    this.eletronicSignatureService = EletronicSignatureService.getInstance();

    if (this.group) {
      this.form = { ...(this.group as GroupForm) };
    }
  }

  async created(): Promise<void> {
    this.loadPartners();
    this.loadUsers();
    this.loadCompanies();
    this.loadApiClients();
  }

  async loadPartners(): Promise<void> {
    this.isLoadingPartnersList = true;
    const [error, partnersData] = await this.partnerService.listPartners({
      page: this.partnersPage,
      limit: this.partnersLimit,
      loadRelations: false
    });
    if (!error) {
      const newPartners = partnersData!.data;
      this.partnersList = [...this.partnersList, ...newPartners];
      this.partnersPage += 1;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.isLoadingPartnersList = false;
  }

  async loadUsers(): Promise<void> {
    if (this.group) {
      this.loadingUsers = true;
      const [error, users] = await this.safetyService.listUsers({
        userIds: [this.group.userId],
        page: 1,
        limit: 1000000,
        types: ["ADMIN_GROUP"]
      });

      if (!error) {
        this.users = users;
      } else {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      }
      this.loadingUsers = false;
    }
  }

  async loadApiClients(): Promise<void> {
    if (this.group) {
      this.loadingUsers = true;
      const [error, clientsApiList] = await this.safetyService.listApiClients({
        page: 1,
        limit: -1
      });

      if (!error) {
        this.clientsApiList = clientsApiList;
      } else {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      }
      this.loadingUsers = false;
    }
  }

  getCompanyDocuments(companyId: number): CompanyDocument[] {
    return this.companyDocuments.filter(
      (companyDocument) => companyDocument.companyId === companyId
    );
  }

  async loadCompanies(): Promise<void> {
    if (this.group) {
      this.loadingCompanies = true;
      const [error, companies] = await this.service.listCompanies({
        groupId: this.group.id,
        page: 1,
        limit: 1000000
      });

      if (!error) {
        this.companies = companies!;
        this.loadCompaniesDocuments();
      } else {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      }
      this.loadingCompanies = false;
    }
  }

  async loadCompaniesDocuments(): Promise<void> {
    this.companyDocuments.length = 0;
    for (const company of this.companies.items) {
      const [companyDocumentsError, companyDocuments] =
        await this.service.listCompanyDocuments(company.id);

      if (companyDocuments && !companyDocumentsError) {
        companyDocuments.forEach((companyDocument) => {
          this.companyDocuments.push(companyDocument);
        });
      }

      if (company.clicksignDocumentKey) {
        const [clickSignDocumentError, clickSignDocument] =
          await this.eletronicSignatureService.showDocument(
            company.clicksignDocumentKey
          );

        if (clickSignDocument && !clickSignDocumentError) {
          var companyDocument = {
            url: clickSignDocument.download_url,
            type: {
              description: "Contrato Gooroo",
              id: 0,
              name: "CONTRATO_GOOROO",
              created_at: new Date(),
              updated_at: new Date()
            } as CompanyDocumentType,
            typeId: 0,
            sentDate: company.clicksignWebhookEventDate || new Date(),
            id: 0,
            companyId: company.id,
            company: company,
            created_at: new Date(),
            updated_at: new Date()
          } as CompanyDocument;
          this.companyDocuments.push(companyDocument);
        }
      }
    }
  }

  async save(): Promise<void> {
    let error, group;

    const form = { ...this.form };

    if (!this.isAdminGooroo) {
      form.requireBorrowerSendDocuments = undefined;
    }

    if (this.group) {
      // Update existing Group
      [error, group] = await this.service.updateGroup(this.group.id!, form);
    } else {
      // Create new Group
      [error, group] = await this.service.createGroup(form);
    }

    if (!error) {
      this.$notify({ type: "success", text: "Grupo salvo com sucesso" });
      this.$emit("input", group);
      this.close();
    } else {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }
  }

  async inactivateGroup(group: Group): Promise<void> {
    this.loading = true;
    const [error] = await this.service.inactivateGroup(group.id);
    if (!error) {
      group.statusId = GroupStatusEnum.INACTIVE;
      this.$emit("input", group);
      this.$notify({ type: "success", text: "Grupo inativado com sucesso." });
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.$emit("close");
    this.loading = false;
  }

  async activateGroup(group: Group): Promise<void> {
    this.loading = true;
    const [error] = await this.service.activateGroup(group.id);
    if (!error) {
      group.statusId = GroupStatusEnum.ACTIVE;
      this.$emit("input", group);
      this.$notify({ type: "success", text: "Grupo ativado com sucesso." });
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.$emit("close");
    this.loading = false;
  }

  async deleteGroup(group: Group): Promise<void> {
    const userResponse = confirm(
      "Você está prestes a excluir um grupo, com isso as empresas, as base de margem e os usuarios vinculados seram excluidos tambem. Deseja continuar?"
    );

    if (userResponse && group.id) {
      this.loading = true;
      const [error] = await this.service.deleteGroup(group.id);
      if (!error) {
        this.$notify({ type: "success", text: "Grupo deletado com sucesso." });
      } else {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      }
      this.$emit("input", group);
      this.close();
      this.loading = false;
    }
  }

  close(): void {
    this.$emit("close");
  }

  getUserType(type: string): string | void {
    const userType = this.safetyService.userTypes.find((t) => t.value == type);
    if (userType) {
      return userType.text;
    }
  }

  formatDate(date: string): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  get formTitle(): string {
    return this.group ? "Editar grupo" : "Novo grupo";
  }

  get authenticatedUser(): boolean {
    return store.getters["auth/authenticatedUser"];
  }

  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }

  get isPartner(): boolean {
    return ["PARTNER_MASTER", "PARTNER"].includes(
      this.$store.getters["auth/authenticatedUser"]?.type
    );
  }

  get loggedPartnerId(): number {
    return this.$store.getters["auth/loggedPartnerId"];
  }

  get hasSaveAuthorization(): boolean {
    if (!this.isPartner || !this.group) return true;

    if (this.loggedPartnerId === this.form?.partnerId) {
      return true;
    }
    return false;
  }
}
