
import { Vue, Component, Prop } from "vue-property-decorator";
import UploadDialog from "@/components/UploadDialog.vue";
import CompanyDocument from "@/types/company-document";

@Component({
  components: {
    UploadDialog
  }
})
export default class CompanyDocumentUpload extends Vue {
  fileManagerUrl: string;
  bucket: string;
  @Prop() companyDocument?: CompanyDocument;
  @Prop() label!: string;

  constructor() {
    super();
    this.fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
    this.bucket = process.env.VUE_APP_COMPANY_DOCUMENTS_BUCKET || "";
  }
}
