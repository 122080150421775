
import { Vue, Prop, Component } from "vue-property-decorator";

interface Status {
  id: number;
  name: string;
}

@Component
export default class GroupStatus extends Vue {
  @Prop() readonly status!: Status;
  statusColors = {
    ATIVO: "green",
    INATIVO: "purple"
  };
  constructor() {
    super();
  }
}
