
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import dayjs from "dayjs";
import CompanyStatus from "../../types/company-status";
import Company from "../../types/company";

@Component({
  components: { ValidationObserver }
})
export default class CompanyUpdateStatusModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop() readonly item!: Company;
  isLoading: boolean = false;
  observation: string = "";
  status: string | null = null;

  constructor() {
    super();
  }

  handleUpdateStatus(): void {
    this.isLoading = true;
    this.$emit("input", {
      id: this.item?.id,
      status: this.status,
      observation: this.observation
    });
    this.status = null;
    this.observation = "";
  }

  get possibleStatus(): Array<CompanyStatus> {
    return this.item?.status?.possibleStatus || [];
  }

  formatDate(date?: Date | null | undefined): string {
    return date ? dayjs(date).format("DD/MM/YYYY") : "";
  }
}
