type MaxContractsActivatedType = {
  id: number;
  name: string;
  description: string;
  totalContracts: number;
};

/*
 * All existing MaxContracts
 */

const unlimited: MaxContractsActivatedType = {
  id: 1,
  name: "Ilimitado",
  description: "Ilimitado",
  totalContracts: 0
};

const oneContract: MaxContractsActivatedType = {
  id: 5,
  name: "1 Contratos",
  description: "1 Contratos",
  totalContracts: 1
};

const twoContracts: MaxContractsActivatedType = {
  id: 2,
  name: "2 Contratos",
  description: "2 Contratos",
  totalContracts: 2
};

const threeContracts: MaxContractsActivatedType = {
  id: 3,
  name: "3 Contratos",
  description: "3 Contratos",
  totalContracts: 3
};

const tenContracts: MaxContractsActivatedType = {
  id: 4,
  name: "10 Contratos",
  description: "10 Contratos",
  totalContracts: 10
};

/*
 * Enum
 */

export const MaxContractsActivatedEnum = {
  UNLIMITED: unlimited,
  ONE_CONTRACT: oneContract,
  TWO_CONTRACTS: twoContracts,
  THREE_CONTRACTS: threeContracts,
  TEN_CONTRACTS: tenContracts
};

