
import { Vue, Prop, Component } from "vue-property-decorator";
import { statusColor, statusDescription } from "@/types/company-status";

interface Status {
  id: number;
  name: string;
}

@Component
export default class CompanyStatus extends Vue {
  @Prop() readonly status!: Status;
  statusColor = statusColor;
  statusDescription = statusDescription;

  constructor() {
    super();
  }
}
