
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Modal from "@/components/Modal.vue";
import Group from "@/types/group";
import CompanyService from "@/services/company-service";
import CompanyDocumentUpload from "@/components/company-registration/CompanyDocumentUpload.vue";
import { mask } from "vue-the-mask";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { calculateDefaultPaymentCutDay } from "@/utils/calculateDefaultPaymentCutDay";
import municipios from "@/data/municipios";
import { Segments } from "@/data/segmentos";
import { SegmentsAvailableForSelfRegister } from "@/data/segmentos";
import CompanyDocument from "@/types/company-document";
import CompanyCreate, {
  CompanyCreateContact,
  CompanyCreateRepresentative
} from "@/types/company-create";
import SaveButton from "@/components/SaveButton.vue";
import Segment from "@/types/segment";
import CompanyRegistrationAdminData from "@/types/company-registration-admin-data";
import dayjs from "@/plugins/day-js";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";
import getErrorMessageForLandlineOrMobilePhoneValidation from "@/utils/getErrorMessageForLandlineOrMobilePhoneValidation";
import { INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE } from "@/utils/isValidPassword";
import DatePicker from "@/components/DatePicker.vue";

@Component({
  components: {
    ValidationObserver,
    Modal,
    SaveButton,
    CompanyDocumentUpload,
    DatePicker
  },
  directives: { mask }
})
export default class CompanyManageModal extends Vue {
  @Prop() show!: boolean;
  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;
  getErrorMessageForLandlineOrMobilePhoneValidation =
    getErrorMessageForLandlineOrMobilePhoneValidation;
  INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE =
    INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE;
  loading: boolean = false;
  service: CompanyService;
  groupsList: Array<Group>;
  states = municipios.estados;
  cities: string[] = [];
  segments = Segments;

  isPartner: boolean = false;
  isProcurator: boolean = false;
  isNeitherPartnerNorProcurator: boolean = false;
  isNotGoingToSignContract: boolean = false;

  companyDocuments: CompanyDocument[];
  isLoadingDocuments: boolean = false;
  isLoading: boolean = false;
  isActive: boolean = false;
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";

  groupForm: CompanyRegistrationAdminData = {
    responsibleName: "",
    responsibleEmail: "",
    responsiblePhone: "",
    responsiblePassword: ""
  };

  companyContactTypes = [
    {
      id: 1,
      name: "COMERCIAL",
      description: "Comercial"
    },
    {
      id: 2,
      name: "FINANCEIRO",
      description: "Financeiro"
    },
    {
      id: 3,
      name: "RH",
      description: "RH"
    },
    {
      id: 4,
      name: "JURIDICO",
      description: "Jurídico"
    },
    {
      id: 5,
      name: "TI",
      description: "TI"
    },
    {
      id: 5,
      name: "OUTROS",
      description: "Outros"
    }
  ];

  emptyContact: CompanyCreateContact = {
    email: "",
    mobilePhoneWithAreaCode: "",
    typeName: "",
    phone: "",
    phoneExtension: ""
  };

  emptyCompanyRepresentative: CompanyCreateRepresentative = {
    type: "PARTNER",
    name: "",
    cpf: "",
    phone: "",
    email: "",
    birthDate: "",
    documents: []
  };

  form: CompanyCreate = {
    groupId: 0,
    name: "",
    cnpj: "",
    segmentName: "",
    segmentId: 0,
    zipCode: "",
    address: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
    phone: "",
    payCutDay: 0,
    expirationDay: 0,
    paymentDay: 0,
    companyRepresentatives: [],
    documents: [],
    contacts: [
      {
        ...this.emptyContact
      }
    ],
    foundationDate: ""
  };

  constructor() {
    super();

    this.service = CompanyService.getInstance();
    this.groupsList = [];
    this.companyDocuments = [];
    if (!this.isAdminGooroo) {
      this.segments = SegmentsAvailableForSelfRegister;
    }
  }

  async mounted(): Promise<void> {
    this.fetchGroups();
    this.isLoading = false;
  }

  async switchActiveInactive(shouldBeActive: boolean): Promise<void> {
    if (shouldBeActive) {
      this.$emit("activate");
    } else {
      this.$emit("block");
    }
    this.isLoading = true;
  }

  async fetchGroups(): Promise<void> {
    const [error, groupsData] = await this.service.listGroups({
      page: 1,
      limit: -1,
      sort: "name:ASC",
      groupName: "",
      companyNameOrCnpj: "",
      adminEmail: "",
      adminPhone: ""
    });
    const groups = groupsData.items;
    if (!error) {
      this.groupsList = [...this.groupsList, ...groups];
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
  }

  addContact(): void {
    const contacts = this.form.contacts || [];
    contacts.push({ ...this.emptyContact });
    this.form.contacts = contacts;
  }

  removeContact(index: number): void {
    if (this.form.contacts) {
      this.form.contacts.splice(index, 1);
    }
  }

  loadCities(): void {
    if (this.form.state) {
      this.cities = municipios.cidadePorEstado(this.form.state);
    } else {
      this.cities = [];
    }
    if (this.form.city && !this.cities.includes(this.form.city)) {
      this.form.city = "";
    }
  }

  addDocument = async (data: {
    typeName: string;
    url: string;
  }): Promise<void> => {
    const documents = this.form.documents || [];
    documents.push(data);
    this.form.documents = documents;
  };

  addRepresentativeDocument = async (
    representativeIndex: number,
    data: {
      typeName: string;
      url: string;
    }
  ): Promise<void> => {
    const documents =
      this.form.companyRepresentatives[representativeIndex].documents || [];
    documents.push(data);
    this.form.companyRepresentatives[representativeIndex].documents = documents;
  };

  setSegment = (segment: Segment): void => {
    if (segment) {
      this.form.segmentId = segment.id;
      this.form.segmentName = segment.name;
    }
  };

  handleIsPartnerToggle(value: boolean): void {
    this.eraseCompanyRepresentatives();
    if (value === true) {
      this.isProcurator = false;
      this.isNeitherPartnerNorProcurator = false;
      this.isNotGoingToSignContract = false;
      this.addPartner();
    }
  }

  handleIsProcuratorToggle(value: boolean): void {
    this.eraseCompanyRepresentatives();
    if (value === true) {
      this.isPartner = false;
      this.isNeitherPartnerNorProcurator = false;
      this.isNotGoingToSignContract = false;
      this.addProcurator();
    }
  }

  handleIsNeitherPartnerNorProcuratorToggle(value: boolean): void {
    this.eraseCompanyRepresentatives();
    if (value === true) {
      this.isPartner = false;
      this.isProcurator = false;
      this.isNotGoingToSignContract = false;
      this.addEmptyCompanyPartner();
    }
  }

  handleIsNotGoingToSignContract(value: boolean): void {
    this.eraseCompanyRepresentatives();
    if (value === true) {
      this.isPartner = false;
      this.isProcurator = false;
      this.isNeitherPartnerNorProcurator = false;
    }
  }

  addPartner(): void {
    this.form.companyRepresentatives = [
      {
        name: this.groupForm.responsibleName,
        email: this.groupForm.responsibleEmail,
        phone: this.groupForm.responsiblePhone,
        type: "PARTNER",
        documents: [],
        cpf: "",
        birthDate: ""
      } as CompanyCreateRepresentative
    ];
  }

  addProcurator(): void {
    this.form.companyRepresentatives = [
      {
        name: this.groupForm.responsibleName,
        email: this.groupForm.responsibleEmail,
        phone: this.groupForm.responsiblePhone,
        type: "PROCURATOR",
        documents: [],
        cpf: "",
        birthDate: ""
      } as CompanyCreateRepresentative
    ];
  }

  async eraseCompanyRepresentatives(): Promise<void> {
    this.form.companyRepresentatives = [];
  }

  removeCompanyRepresentativeAtIndex(index: number): void {
    if (this.form.companyRepresentatives) {
      this.form.companyRepresentatives.splice(index, 1);
    }
  }

  addEmptyCompanyPartner(): void {
    this.form.companyRepresentatives.push({
      ...this.emptyCompanyRepresentative
    });
  }

  async save(): Promise<void> {
    this.loading = true;
    const form: CompanyCreate = JSON.parse(JSON.stringify(this.form));
    form.number = form.number.replace(/[^\d]+/g, "");
    form.cnpj = form.cnpj.replace(/[^\d]+/g, "");
    form.zipCode = form.zipCode.replace(/[^\d]+/g, "");
    form.phone = form.phone.replace(/[^\d]+/g, "");

    if (form.segmentId) {
      const segment = this.segments.find(
        (segment) => segment.id === form.segmentId
      );

      if (segment) {
        form.segmentName = segment.name;
      }
    }

    form.companyRepresentatives.forEach((rep: CompanyCreateRepresentative) => {
      rep.cpf = rep.cpf.replace(/[^\d]+/g, "");
      rep.birthDate = dayjs(rep.birthDate, "DD/MM/YYYY").toISOString();
    });

    form.contacts.forEach((contact: CompanyCreateContact) => {
      contact.phone = contact.phone.replace(/[^\d]+/g, "");
    });

    if (form.groupId === 0) {
      const [groupError, group] = await this.service.createGroup({
        name: this.form.name,
        admin_name: this.groupForm.responsibleName,
        admin_email: this.groupForm.responsibleEmail,
        admin_phone: this.groupForm.responsiblePhone,
        admin_password: this.groupForm.responsiblePassword
      });

      if (group) {
        this.groupsList.push(group);
        this.form.groupId = group.id;
        form.groupId = group.id;
      } else if (groupError) {
        const message = getErrorMessageFromApiError(groupError);
        this.$notify({
          title: "Erro ao criar o grupo",
          type: "error",
          text: message
        });
        return;
      }
    }

    const [companyError, company] = await this.service.createCompany(form);

    this.loading = false;

    if (company) {
      this.$emit("input", company);
      this.$notify({
        title: "Empresa cadastrada com sucesso",
        type: "success"
      });
    } else if (companyError) {
      const message = getErrorMessageFromApiError(companyError);
      this.$notify({
        title: "Erro ao registrar empresa",
        type: "error",
        text: message
      });
      return;
    }
  }

  expirationDayChanged(): void {
    if (this.form.expirationDay) {
      const expirationDay = Number(this.form.expirationDay);

      this.form.payCutDay = calculateDefaultPaymentCutDay(expirationDay);
    }
  }

  get hasSelectedAnOption(): boolean {
    return (
      this.isPartner ||
      this.isProcurator ||
      this.isNeitherPartnerNorProcurator ||
      this.isNotGoingToSignContract
    );
  }

  get hasSelectedAnOptionThatRequiresCompanyRepresentatives(): boolean {
    return (
      this.isPartner || this.isProcurator || this.isNeitherPartnerNorProcurator
    );
  }

  get hasReachedRepresentativesLimit(): boolean {
    return this.form.companyRepresentatives.length >= 2;
  }
  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }

  get canAddNewGroup(): boolean {
    return ["ADMIN_GOOROO", "PARTNER_MASTER", "PARTNER"].includes(
      this.$store.getters["auth/authenticatedUser"]?.type
    );
  }
}
