import { render, staticRenderFns } from "./CompanyStatus.vue?vue&type=template&id=dbead11e"
import script from "./CompanyStatus.vue?vue&type=script&lang=ts"
export * from "./CompanyStatus.vue?vue&type=script&lang=ts"
import style0 from "./CompanyStatus.vue?vue&type=style&index=0&id=dbead11e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports