
import { Vue, Component } from "vue-property-decorator";
import Group from "@/types/group";
import { DataOptions, DataTableHeader } from "vuetify";
import CompanyService from "@/services/company-service";
import GroupsManageModal from "@/components/companies/GroupsManageModal.vue";
import debounce from "debounce";
import Modal from "@/components/Modal.vue";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import GroupStatus from "@/components/companies/GroupStatus.vue";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";

interface GroupFilters {
  page: number;
  limit: number;
  sort: string;
  groupName: string;
  companyNameOrCnpj: string;
  adminEmail: string;
  adminPhone: string;
}

interface GroupList {
  items: Array<Group>;
  total: number;
}
@Component({
  components: {
    GroupsManageModal,
    Modal,
    GroupStatus
  }
})
export default class GroupsManage extends Vue {
  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;
  service: CompanyService;
  groups: GroupList;
  filters: GroupFilters;
  headers: Array<DataTableHeader>;
  addGroup = false;
  editGroup?: Group | null = null;
  showGroups = false;
  loading = false;

  constructor() {
    super();
    this.service = CompanyService.getInstance();
    this.headers = [
      { text: "Nome", value: "name", sortable: true },
      { text: "Administrador", value: "adminName", sortable: false },
      { text: "Status", sortable: false, value: "status" },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.filters = {
      page: 1,
      limit: -1,
      sort: "name:DESC",
      groupName: "",
      companyNameOrCnpj: "",
      adminEmail: "",
      adminPhone: ""
    };
    this.groups = {
      items: [],
      total: 0
    };
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    this.fetchGroups();

    return tableOptions;
  }

  handleSearch = debounce(() => {
    this.filters.page = 1;
    this.filters.sort = "name:ASC";
    this.fetchGroups();
  }, 700);

  async fetchGroups(): Promise<GroupList> {
    this.loading = true;
    const [error, groups] = await this.service.listGroups(this.filters);
    if (!error) {
      this.groups = groups;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loading = false;
    return this.groups;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ?? "name";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return `${attr}:${order}`;
  }

  closeModal() {
    this.addGroup = false;
    this.editGroup = null;
    this.$emit("close");
  }
}
